<template>
  <div>
    <el-dialog
      title="制程导入"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <el-card class="grid-card" shadow="hover">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold">制程xlsx文件导入</span>
        </div>
        <el-row :gutter="10" style="margin-left: 10px">
          <el-col :span="24">
            <el-upload
              class="dataImport"
              ref="dataImport"
              :action="importApiUrl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
              accept=".xlsx"
              :data="importData"
              :on-exceed="handleExceed"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-change="handleChange"
              :auto-upload="false"
            >
              <el-button slot="trigger" icon="el-icon-folder-add" size="small"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                请上传xlsx文件, 且不超过80MB
              </div>
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                title="确定要导入文件吗？"
                @confirm="submitDataImport()"
              >
                <el-button
                  style="margin-left: 10px"
                  icon="icon-daoru"
                  size="small"
                  type="primary"
                  slot="reference"
                  >开始导入</el-button
                >
              </el-popconfirm>
            </el-upload>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>

    <SchemaTable
      ref="schematableref"
      :schema="schema"
      :model="dataModel"
      :search="search"
      :filters="filters"
      :queryConf="queryConf"
      :hasReset="hasReset"
    >
      <template #queryBtns="{ row }">
        <!-- 用 el-link制作按钮，添加 action-link 的 class 增加间距 -->
        <el-button
          type="primary"
          plain
          style="float: right"
          icon="el-icon-upload2"
          @click="importMac(row)"
        >
          导入
        </el-button>
      </template>
    </SchemaTable>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./mac.schema.json";
import { uris, attachId } from "./../../api/uri";
import axios from "axios";

export default {
  components: {
    SchemaTable,
  },
  data() {
    return {
      importApiUrl: "",
      search: "",
      filters: ["mac", "orderId"],
      schema,
      fileList: [],
      importData: {},
      hasReset: true,
      dialogVisible: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.mac}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          item.orderName = item.order ? item.order.name : "";
          if (item.order && item.order.product)
            item.productName = item.order.product.name;
          return item;
        },
        getApi: `${uris.mac}${attachId}`,
        getMap(res) {
          res.orderName = res.order ? res.order.name : "";
          return res;
        },
        deleteApi: `${uris.mac}${attachId}`,
        createApi: uris.mac,
        // updateApi: `${uris.factory}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    importMac() {
      this.importApiUrl =
        axios.defaults.baseURL +
        "/api/v1/mac/import?authorization=" +
        localStorage.getItem("token");
      this.dialogVisible = true;
    },
    async submitDataImport() {
      if (this.importData.md5) {
        this.$refs.dataImport.submit();
      } else {
        this.$message.error("请选择xlsx文件！");
      }
    },

    // 导入文件相关方法（上传文件）
    handleRemove(file, fileList) {
      this.importData.md5 = "";
      console.log(file, fileList);
    },

    handlePreview(file) {
      console.log(file);
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    async handleSuccess(response, file, fileList) {
      if (!response.code) {
        this.importData.md5 = "";
        this.$message({
          message: "MAC导入成功",
          type: "success",
        });
        await this.handleClose();
      } else {
        this.$message({
          message: response.message,
          type: "warning",
        });
        await this.handleClose();
      }
    },

    async handleChange(file) {
      this.importData.size = file.size;
      const that = this;
      const fileReader = new FileReader();
      const dataFile = file.raw;
      // const spark = new SparkMD5.ArrayBuffer();
      fileReader.readAsArrayBuffer(dataFile);
      //异步执行函数
      fileReader.onload = function (e) {
        // spark.append(e.target.result);
        // const md5 = spark.end();
        // console.log(md5);
        //此处this指向发生改变，需提前声明const _this = this
        // that.importData.md5 = md5; //此处是将文件的md5值放入dataImport中
        that.importData.md5 = "none"; // 复制为了后续检测
      };
    },

    async handleClose() {
      this.$refs.schematableref.onSearch();
      this.$refs.dataImport.clearFiles();
      this.dialogVisible = false;
    },

    async handleError(err, file, fileList) {
      if (err.status == 413) {
        this.$notify.error({
          title: "错误",
          message: "文件大小超出限制",
        });
      } else {
        this.$notify.error({
          title: "错误",
          message: "不支持此文件格式上传",
        });
      }
    },
  },
};
</script>

<style scoped>
</style>